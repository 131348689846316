//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Companylogo',
    computed: {
        slug() {
            const { locale, defaultLocale } = this.$i18n;
            return locale !== defaultLocale ? `/${locale}` : '/';
        },
    },
};
