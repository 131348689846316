import axios from "axios";

export default class User {
    constructor(config, locale) {
        this.config = config;
        this.locale = locale;
    }

    userData() {
        return new Promise((resolve) => {
            const accessToken = window.localStorage.getItem('access_token');

            if (!accessToken) {
                return resolve(false);
            }

            axios
                .post(`${this.config.pimcoreUrl}/is_logged_in`, {}, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                })
                .then((res) => resolve(res.data))
                .catch((e) => {
                    console.error(e);
                    resolve(false);
                });
        });
    }

    getConfigurationCollections(loadConfigurations = false) {
        return new Promise((resolve) => {
            const accessToken = window.localStorage.getItem('access_token');

            if (!accessToken) {
                return resolve([]);
            }

            const formData = new FormData();
            formData.append('loadConfigurations', loadConfigurations);

            axios
                .post(`${this.config.pimcoreUrl}/configuration_collections`, formData, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                })
                .then((res) => resolve(res.data))
                .catch((e) => {
                    console.error(e);
                    resolve([]);
                });
        });
    }

    getConfigurationCollection(collectionId) {
        return new Promise((resolve) => {
            const accessToken = window.localStorage.getItem('access_token');

            axios
                .post(`${this.config.pimcoreUrl}/configuration_collections/${collectionId}`, null, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                })
                .then((res) => resolve(res.data))
                .catch((e) => {
                    console.error(e);
                    resolve([]);
                });
        });
    }

    removeConfiguration(code) {
        return new Promise((resolve) => {
            const accessToken = window.localStorage.getItem('access_token');

            if (!accessToken) {
                return resolve([]);
            }

            axios
                .post(`${this.config.pimcoreUrl}/remove_configuration/${code}`, null, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                })
                .then((res) => resolve(res.data))
                .catch((e) => {
                    console.error(e);
                    resolve([]);
                });
        });
    }

    changeSetName(set) {
        return new Promise((resolve) => {
            const accessToken = window.localStorage.getItem('access_token');

            if (!accessToken) {
                return resolve([]);
            }

            const formData = new FormData()
            formData.append('name', set.name);

            axios
                .post(`${this.config.pimcoreUrl}/change_set_name/${set.id}`, formData, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                })
                .then((res) => resolve(res.data))
                .catch((e) => {
                    console.error(e);
                    resolve([]);
                });
        });
    }

    removeConfigurationCollection(collectionId) {
        return new Promise((resolve) => {
            const accessToken = window.localStorage.getItem('access_token');

            if (!accessToken) {
                return resolve([]);
            }

            axios
                .post(`${this.config.pimcoreUrl}/remove_configuration_collection/${collectionId}`, null, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                })
                .then((res) => resolve(res.data))
                .catch((e) => {
                    console.error(e);
                    resolve([]);
                });
        });
    }

    downloadPdfs(arrayWithCodes) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this.config.pimcoreUrl}/${this.locale}/download_pdfs`, {codes: arrayWithCodes}, {
                    responseType: 'blob',
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch(reject);
        });
    }

    register(data) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('email', data.email);
            formData.append('password', data.password);
            formData.append('lastName', data.lastname);
            formData.append('firstName', data.firstname);
            formData.append('surname', data.surname);
            formData.append('captcha', data.captcha);
            formData.append('type', data.usertype);
            axios.post(`${this.config.pimcoreUrl}/${this.locale}/register`, formData, {
                withCredentials: true,
            }).then(resolve).catch(reject);
        });
    }

    login(data) {
        return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('email', data.email);
            formData.append('password', data.password);
            axios.post(`${this.config.pimcoreUrl}/login`, formData).then(resolve).catch(reject);
        });
    }

    confirm(data) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('token', data.token);
            axios.post(`${this.config.pimcoreUrl}/confirm`, formData).then(resolve).catch(reject);
        });
    }

    confirmNewEmail(data) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('token', data.token);
            axios.post(`${this.config.pimcoreUrl}/confirm_new_email`, formData).then(resolve).catch(reject);
        });
    }

    deleteUser() {
        return new Promise((resolve, reject) => {
            const accessToken = window.localStorage.getItem('access_token');
            if (!accessToken) {
                return resolve(null);
            }
            axios.post(`${this.config.pimcoreUrl}/delete_user`, null, {
                headers: {
                    'X-Auth': `${accessToken}`
                },
            }).then(resolve).catch(reject);
        });
    }

    setNewPassword(data) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('token', data.token);
            formData.append('password', data.password);
            axios.post(`${this.config.pimcoreUrl}/set_new_password`, formData).then(resolve).catch(reject);
        });
    }

    resetPassword(email, captcha) {
        return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('email', email);
            formData.append('captcha', captcha);
            axios.post(`${this.config.pimcoreUrl}/${this.locale}/reset_password`, formData, {
                withCredentials: true
            }).then(resolve).catch(reject);
        });
    }

    updateData(data) {
        return new Promise((resolve, reject) => {
            const accessToken = window.localStorage.getItem('access_token');
            if (!accessToken) {
                return resolve(null);
            }
            const formData = new FormData();
            formData.append('firstName', data.firstname);
            formData.append('lastName', data.lastname);
            formData.append('email', data.email);
            formData.append('type', data.usertype);
            if(data.password) {
                formData.append('password', data.password);
            }
            axios.post(`${this.config.pimcoreUrl}/${this.locale}/update_profile`, formData,  {
                headers: {
                    'X-Auth': `${accessToken}`
                }
            }).then(resolve).catch(reject);
        });
    }

    createConfigrationCollection(collectionName) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('name', collectionName);

            const accessToken = window.localStorage.getItem('access_token');
            if (!accessToken) {
                return resolve(null);
            }

            axios
                .post(`${this.config.pimcoreUrl}/create_configuration_collection`, formData, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                })
                .then((res) => resolve(res.data))
                .catch((e) => {
                    console.error(e);
                    resolve(null);
                });
        });
    }

    saveConfiguratorCodeToCollection(code, collection) {
        return new Promise((resolve, reject) => {
            const accessToken = window.localStorage.getItem('access_token');

            if (accessToken) {
                const formData = new FormData()
                formData.append('code', code);
                formData.append('collection', collection);

                axios.post(`${this.config.pimcoreUrl}/save_configuration_collection`, formData, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                }).then(() => {
                    resolve(true);
                }).catch(reject);
            } else {
                reject();
            }
        });
    }

    saveConfiguratorCode() {
        return new Promise((resolve, reject) => {
            const code = window.localStorage.getItem('save_after_login')
            if (!code) {
                return resolve(false);
            }

            const accessToken = window.localStorage.getItem('access_token');

            if (accessToken) {
                const formData = new FormData()
                formData.append('code', code);

                axios.post(`${this.config.pimcoreUrl}/save_configuration`, formData, {
                    headers: {
                        'X-Auth': `${accessToken}`
                    }
                }).then(() => {
                    window.localStorage.removeItem('save_after_login');
                    resolve(true);
                }).catch(reject);
            } else {
                reject();
            }
        });
    }
}
