import axios from 'axios';
import Cache from './cache';


process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = 0;

export default class ProductApi {
    constructor(preview, i18n, locales, store, config) {
        this.preview = preview;
        this.i18n = i18n;
        this.locale = i18n.locale;
        this.locales = locales;
        this.store = store;
        this.config = config;
        this.cache = new Cache(preview === true ? false : parseInt(config.cache, 10) === 1);

    }

    getProductColorSet(name) {
        let localizedName = '';
        this.locales.forEach((item) => {
            localizedName += `${item.code}: name(language: "${item.code}")`;
        });
        const query = JSON.stringify({
            query: `
                {
                  colorSet: getProductColorSetListing(defaultLanguage: "de", filter: "{\\"name\\": \\"${name}\\"}") {
                    edges {
                      node {
                        ... on object_ProductColorSet {
                          colors {
                          ... on object_ProductColor {
                                id
                                rgb
                                surface
                                name
                                extKey
                                colorClass
                                colorImage {
                                    id
                                    fullpath
                                }
                                ${localizedName}
                                parent {
                                  ... on object_ProductColorCategory {
                                    id
                                    key
                                    name
                                    ${localizedName}
                                  }
                                }
                              }
                          }
                        }
                      }
                    }
                  }
                }
            `,
        });

        return new Promise((resolve) => {
            name = name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
            const cached = this.cache.getProductColorSet(name);
            if (cached) {
                return resolve(cached);
            }

            axios.post(this.config.apiUrl, query).then(async (res) => {
                if (res.data.data && res.data.data.colorSet && res.data.data.colorSet.edges && res.data.data.colorSet.edges.length) {
                    const colorSet = res.data.data.colorSet.edges[0].node;

                    this.cache.setProductColorSet(name, colorSet);
                    resolve(colorSet);
                } else {
                    resolve(null);
                }
            }).catch((e, res) => {
                resolve(null);
            })
        });
    };

    getProductLocalizedSlugs(id) {
        let querySlugs = '';
        this.locales.forEach((item) => {
            querySlugs += `${item.code}: getProduct(id:${id}, defaultLanguage: "${item.code}") { id slug}`;
        });
        const query = JSON.stringify({ query: `{${querySlugs}}` });
        return new Promise((resolve) => {
            axios.post(this.config.apiUrl, query).then(async (res) => {
                if (res.data.data) {
                    resolve(res.data.data);
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                resolve(null);
                console.error(e, query)
            })
        });
    }

    async preprocessProductAttributes(product, skipColorSet = false) {
        const localProductCache = {};
        const localColorSetCache = {};

        for (const variant of product.variants) {
            let accessoriesAndExtras = [].concat(variant.accessories, variant.extras);
            for (const item of accessoriesAndExtras) {
                if (item.parentId && !item.slug) {
                    const parentProduct = localProductCache[item.parentId] || await this.getProductById(item.parentId);
                    if (parentProduct) {
                        localProductCache[item.parentId] = parentProduct;
                        item.images = parentProduct.images;
                        item.slug = `${parentProduct.slug}`;
                        item.accessoryCategory = parentProduct.accessoryCategory;
                        item.cannonical_accessoryCategory = parentProduct.cannonical_accessoryCategory;
                        item.suitableFor = parentProduct.suitableFor;
                    }
                }
            }
            if (variant.colorSet && !skipColorSet) {
                let variantColors = [];
                const colorSets = variant.colorSet.split(',');

                for (const set of colorSets) {
                    const colorSet = localColorSetCache[set] || await this.getProductColorSet(set);
                    if (colorSet && colorSet.colors && colorSet.colors.length) {
                        localColorSetCache[set] = colorSet;
                        variantColors = [...variantColors, ...colorSet.colors];
                    }
                }
                const ids = variantColors.map(o => o.id)
                variant.colors = variantColors.filter(({ id }, index) => !ids.includes(id, index + 1));
            }
        }

        if (product.accessories && product.accessories.length) {
            for (const item of product.accessories) {
                if (item.parentId) {
                    const parentProduct = localProductCache[item.parentId] || await this.getProductById(item.parentId);
                    if (parentProduct) {
                        localProductCache[item.parentId] = parentProduct;
                        if (!item.images || item.images.length === 0) {
                            item.images = parentProduct.images;
                        }

                        item.slug = parentProduct.slug;
                        item.accessoryCategory = parentProduct.accessoryCategory;
                        item.cannonical_accessoryCategory = parentProduct.cannonical_accessoryCategory;
                        item.suitableFor = parentProduct.suitableFor;
                    }
                }
            }
        }

        if (product.extras && product.extras.length) {
            for (const item of product.extras) {
                if (item.parentId) {
                    const parentProduct = localProductCache[item.parentId] || await this.getProductById(item.parentId);
                    if (parentProduct) {
                        localProductCache[item.parentId] = parentProduct;
                        if (!item.images || item.images.length === 0) {
                            item.images = parentProduct.images;
                        }

                        item.slug = parentProduct.slug;
                        item.accessoryCategory = parentProduct.accessoryCategory;
                        item.cannonical_accessoryCategory = parentProduct.cannonical_accessoryCategory;
                        item.suitableFor = parentProduct.suitableFor;
                    }
                }
            }
        }
        return product;
    }

    getProduct(slug, lang) {
        lang = lang || this.locale;

        return new Promise((resolve) => {
            const cached = this.cache.getSingleProduct(null, lang, slug);

            if (cached) {
                return resolve(cached);
            }
            axios.post(`${this.config.pimcoreUrl}/${lang}/product_by_slug`, null, { params: { slug: slug } }).then(async (res) => {
                if (res.data && res.data.variants) {
                    const product = await this.preprocessProductAttributes(res.data);
                    this.cache.setSingleProduct(res.data.id, lang, product, slug);
                    resolve(product);
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                resolve(false);
                console.error('product error', e)
            })
        })
    }

    listCategory(categoryId, lang) {
        lang = lang || this.locale;

        return new Promise((resolve) => {
            const cached = this.cache.getCategoryList(categoryId, lang);
            if (cached) {
                return resolve(cached);
            }
            axios.post(`${this.config.pimcoreUrl}/${lang}/products_by_category/${categoryId}`).then(async (res) => {
                this.cache.setCategoryList(categoryId, lang, res.data)
                resolve(res.data)

            }).catch((e) => {
                resolve([]);
            })
        })
    }

    getProductSlugByExternalKey(externalKey, lang) {
        lang = lang || this.locale;
        return new Promise((resolve) => {
            axios.post(`${this.config.pimcoreUrl}/${lang}/get_product_slug_by_external_key/${externalKey}`).then(async (res) => {
                if (res.data) {
                    resolve(res.data.slug);
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                resolve(null);
                console.error(e)
            })
        })
    }

    getProducts(lang) {
        lang = lang || this.locale;
        return new Promise((resolve) => {
            const cached = this.cache.getProducts(lang);
            if (cached) {
                return resolve(cached);
            }

            axios.post(`${this.config.pimcoreUrl}/${lang}/products`).then(async (res) => {
                if (res.data) {
                    resolve(res.data);
                    this.cache.setProducts(lang, res.data)
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                resolve(null);
                console.error(e)
            })
        })
    }

    getVariants(product, lang) {
        lang = lang || this.locale;
        return new Promise((resolve) => {
            const cached = this.cache.getVariants(product.id, lang);
            if (cached) {
                return resolve(cached);
            }

            axios.post(`${this.config.pimcoreUrl}/${lang}/variants/${product.id}`).then(async (res) => {
                if (res.data) {
                    resolve(res.data);
                    this.cache.setVariants(product.id, lang, res.data)
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                resolve(null);
                console.error(e)
            })
        })
    }

    getProductById(id, lang) {
        lang = lang || this.locale;

        return new Promise((resolve) => {

            const cached = this.cache.getProduct(id, lang);
            if (cached) {
                return resolve(cached);
            }

            axios.post(`${this.config.pimcoreUrl}/${lang}/products/${id}`).then(async (res) => {
                if (res.data) {
                    const product = await this.preprocessProductAttributes(res.data);
                    this.cache.setProduct(id, lang, product);
                    resolve(product);
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                resolve(null);
                console.error(e)
            })
        })
    }

    getTax(lang) {
        lang = lang || this.locale;

        return new Promise((resolve) => {
            const cached = this.cache.getTax(lang);
            if (cached !== false) {
                return resolve(cached);
            }
            axios.post(`${this.config.pimcoreUrl}/country_taxes`).then(async (res) => {
                let tax = 0;
                if (res.data) {
                    tax = res.data[lang] ? res.data[lang] : 0;
                    resolve(tax);
                } else {
                    resolve(null);
                }
                this.cache.setTax(lang, tax);
            }).catch((e) => {
                resolve(null);
            })
        })
    }

    getProductByVariantId(id, lang, skipColorSet = false) {
        lang = lang || this.locale;

        return new Promise((resolve) => {

            const cached = this.cache.getProduct(id, lang);
            if (cached) {
                return resolve(cached);
            }

            axios.post(`${this.config.pimcoreUrl}/${lang}/product_by_variant/${id}`).then(async (res) => {
                if (res.data) {
                    const product = await this.preprocessProductAttributes(res.data, skipColorSet);
                    this.cache.setProduct(id, lang, product);
                    resolve(res.data);
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                resolve(null);
                console.error(e)
            })
        })
    }

    getProductByVariantIdWithoutProductAttributes(id, lang) {
        lang = lang || this.locale;

        return new Promise((resolve) => {

            const cached = this.cache.getProduct(id, lang);
            if (cached) {
                return resolve(cached);
            }

            axios.post(`${this.config.pimcoreUrl}/${lang}/product_by_variant/${id}`).then(async (res) => {
                if (res.data) {
                    resolve(res.data);
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                resolve(null);
                console.error(e)
            })
        })
    }

    async getProductsByCategoryId(id, lang) {
        lang = lang || this.locale;
        const products = await this.listCategory(id, lang);
        return products
            .sort((a, b) => b.prio < a.prio ? 1 : -1);
    }

    getProductSlug(product) {
        return '/' + this.store.state.siteProperties.product_page.slug.replace('/', '') + '/' + product.slug;
    }

    getCategories(lang) {
        lang = lang || this.locale;
        return new Promise((resolve) => {
            const query = JSON.stringify({
                query: `
                      getProductCategoryListing(defaultLanguage: "${lang}") {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                `,
            });

            axios.post(this.config.apiUrl, query).then(async (res) => {
                if (res.data.data) {
                    resolve(res.data.data.getProductCategoryListing);
                } else {
                    resolve(null);
                }
            }).catch((e) => {
                console.error(e, query)
            })
        })
    }

    getLandingPage(lang, id) {
        lang = lang || this.locale;
        return new Promise((resolve) => {
            axios.post(`${this.config.pimcoreUrl}/${lang}/landing_page/${id}`).then(async (res) => {
                resolve(res.data);
            }).catch((e) => {
                resolve(null);
            })
        })
    }
}
