//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Toast',

    props: {
        //positioning
        //offsets depends on position
        containerVerticalOffset: {
            //top/bottom offset of the container
            type: String,
            default: '35px',
        },

        containerSideOffset: {
            //left/right offset of the container
            type: String,
            default: '5px',
        },

        toastVerticalOffset: {
            //top/bottom offset of toasts
            type: String,
            default: '5px',
        },

        position: {
            type: String,
            default: 'top-right',
        },

        width: {
            type: String,
            default: '300px',
        },

        height: {
            type: String,
            default: '75px',
        },

        name: {
            type: String,
        },

        heading: {
            type: String,
        },

        content: {
            type: String,
        },

        icon: {
            type: String,
        },

        icon_size: {
            type: String,
            default: '23px',
        },

        transition: {
            type: String,
            default: 'slide',
        },

        duration: {
            type: String,
            default: '1000',
        },

        callback: {
            default: null,
        },

        background: {
            type: String,
            default: 'black',
        },

        progress_background: {
            type: String,
            default: 'tomato',
        },

        progress_thumb_bg: {
            type: String,
            default: 'red',
        },

        color: {
            type: String,
            default: 'white',
        },

        _style: {
            type: Object,
            default: null,
        },

        show_progress_timeout: {
            type: String,
            default: 'true',
        },

        link: {
            type: String,
        },

        href: {
            type: String,
        },
    },

    beforeMount() {
        let container = document.querySelector(`.dtoast-ctn.dtoast-${this.position}`);
        if (!container) {
            container = document.createElement('div');

            container.classList.add('dtoast-ctn');
            container.classList.add(`dtoast-${this.position}`);

            container.style.overflow = 'hidden';
            container.style.zIndex = '9999999';

            if (this.$props.position.includes('top')) container.style.marginTop = this.$props.containerVerticalOffset;
            else container.style.marginBottom = this.$props.containerVerticalOffset;

            if (this.$props.position.includes('left')) container.style.marginLeft = this.$props.containerSideOffset;
            else container.style.marginRight = this.$props.containerSideOffset;

            document.body.appendChild(container);
        }
        container.appendChild(this.$el);
    },

    beforeDestroy() {
        this.$el.remove();
    },

    mounted() {
        this.visible = true;
    },

    computed: {
        adaptedStyle() {
            var sObj = {
                background: this.$props.background,
                color: this.$props.color,
                width: this.$props.width,
                'min-height': this.$props.height,
            };

            sObj[this.$props.position.includes('top') ? 'margin-bottom' : 'margin-top'] = this.$props.toastVerticalOffset;

            return sObj;
        },

        adaptedTransition() {
            var trans = this.$props.transition;

            if (this.$props.position.includes('left')) trans += '-left';
            else trans += '-right';

            return trans;
        },
    },

    methods: {
        afterTransitionEnter() {
            const elapsed = setInterval(() => {
                if (this.elapsed_ms >= this.$props.duration) {
                    clearInterval(elapsed);
                    return;
                }

                this.elapsed_ms += 4.1;
            }, 1);

            setTimeout(() => {
                this.hide();
                if (this.callback) {
                    this.callback();
                }
            }, this.$props.duration);
        },

        afterTransitionLeave() {
            this.$emit('hidden');
        },

        hide() {
            this.visible = false;
        },
    },

    data() {
        return {
            visible: false,
            elapsed_ms: 0,
        };
    },
};
