export default {
    /**
     * Stored product and Variants
     */
    browserLanguage: state => {
        return state.browserLanguage;
    },
    /**
     * Stored product and Variants
     */
    getStoredProduct: state => {
        return state.product;
    },
    /**
     * Returns the name of the stored product
     */
    getStoredProductName: (state, getters) => {
        return getters.getStoredProduct.name;
    },
    /**
     * Returns the category type of the stored product
     */
    getStoredProductCategoryType: (state, getters) => {
        return getters.getStoredProduct.category.type;
    },
    /**
     * Returns the category type segment type of the stored product
     */
    getStoredProductCategoryTypeSegment: (state, getters) => {
        return getters.getStoredProduct.category.typeSegment;
    },
    /**
     * Returns the stored/selected product variant
     */
    getStoredProductVariant: state => {
        return state.productVariant;
    },
    /**
     * Returns all variants of the stored product
     */
    getStoredProductVariants: (state, getters) => {
        let items = [...getters.getStoredProduct.variants];
        items.sort((a, b) => {
            return a.prio < b.prio ? -1 : 1;
        })
        return items;
    },
    /**
     * Returns the page slugs (inluding product)
     */
    getPageSlugs: state => {
        return state.pageSlugs;
    },
    /**
     * Returns the menu
     */
    getStoredMenu: state => {
        return state.menu;
    },
    /**
     * Returns the rootline
     */
    getStoredRootline: state => {
        return state.rootLine;
    },
    /**
     * Returns the currently stored page
     */
    getStoredPage: state => {
        return state.page;
    },
    /**
     * Returns the page traslations
     */
    getStoredPageTranslations: state => {
        return state.pageTranslations;
    },
    /**
     * Returns the site properties
     */
    getStoredSiteProperties: state => {
        return state.siteProperties;
    },

    getWatchList: state => {
        return state.watchList;
    },
};
