import {PRODUCT_CATEGORIES_IDS, PRODUCT_CATEGORY_TYPE_SHOWERSURFACE, PRODUCT_CATEGORY_TYPE_SHOWERTILE} from '../constants';

export default {
    pageSlugs(state, {pageItems, slugPrefix, defaultLocale}) {
        let slugs = {};

        // products, merchants
        if (slugPrefix && Object.keys(slugPrefix).length) {
            Object.keys(slugPrefix).forEach(key => {
                let locale = '/';
                if (key !== defaultLocale) {
                    locale = `/${key}/`;
                }
                if (slugPrefix[key].slug) {
                    slugs[key] = `${locale}${slugPrefix[key].slug}/${pageItems[key].slug}`;
                }
            });
        } else {
            // normal pages
            Object.keys(pageItems).forEach(key => {
                const pageItem = pageItems[key];

                if (pageItem.visible) {
                    let rootline = [];
                    //TODO: find invisible page in rootline, don#t list slug with an invisible parent page
                    const findParent = (item) => {
                        if (item.parent && item.parent.slug) {
                            rootline.push(item.parent.slug);
                            if (item.parent) {
                                findParent(item.parent);
                            }
                        }
                    };
                    if (pageItem && pageItem.slug !== null) {
                        rootline.push(pageItem.slug);
                        findParent(pageItem);
                    }
                    if (key !== defaultLocale) {
                        rootline.push(`/${key}`);
                    }
                    slugs[key] = rootline.reverse().join('/').replace(/\/\/+/g, '/');
                }
            });
        }
        state.pageSlugs = slugs;
    },
    menu(state, menu) {
        state.menu = menu;
    },
    browserLanguage(state, val) {
        state.browserLanguage = val;
    },
    rootLine(state, rootLine) {
        state.rootLine = rootLine;
    },
    page(state, page) {
        state.page = page;
    },
    pageTranslations(state, pageTranslations) {
        state.pageTranslations = pageTranslations;
    },
    siteProperties(state, siteProperties) {
        state.siteProperties = siteProperties;
    },
    showProductRangeOverlay(state, opt) {
        state.showProductRangeOverlay = opt;
    },
    product(state, product) {
        const int = (val) => parseInt(val, 10);

        state.product = product;

        // add product category type
        const productCatId = int(product.category.id);
        state.product.category.type = Object.keys(PRODUCT_CATEGORIES_IDS).find(prodCat => PRODUCT_CATEGORIES_IDS[prodCat].includes(productCatId));

        // add/map variant dimensions and sort colors by category
        state.product?.variants?.map((variant) => {
            if (variant && variant.length && variant.width && variant.height) {
                variant.dimensionsString = `${(variant.length ? variant.length.replace('.', ',') : '0')} x ${(variant.width ? variant.width.replace('.', ',') : 0)} x ${(variant.height ? variant.height.replace('.', ',') : 0)} ${this.$i18n.t('dimensions.units')}`;
            }

            if (variant && variant.length && variant.width && variant.height && (product.category.typeSegment === PRODUCT_CATEGORY_TYPE_SHOWERSURFACE || product.category.typeSegment === PRODUCT_CATEGORY_TYPE_SHOWERTILE)) {
                variant.dimensionsString = `${(variant.length ? variant.length.replace('.', ',') : 0)} x ${(variant.width ? variant.width.replace('.', ',') : 0)} ${this.$i18n.t('dimensions.units')}`;
            }

            if (variant && variant.length && variant.width && variant.height && variant.lyingDimension) {
                variant.dimensionsString = `${(variant.length ? variant.length.replace('.', ',') : 0)} x ${(variant.width ? variant.width.replace('.', ',') : 0)} x ${(variant.height ? variant.height.replace('.', ',') : 0)} / ${variant.lyingDimension}`;
            }
            // sort colors by parent categories
            let colorCategories = {};
            if (variant.colors && variant.colors.length) {
                variant.colors.forEach(color => {
                    if (color.parent.id) {
                        let idString = color.surface.trim();
                        let catObj = {
                            name: this.$i18n.t('color.group.' + color.surface.trim()),
                            key: color.surface,
                        };

                        // Own group for Bicolour, Gestellfarben, Stofffarben
                        if (['530', '4252', '4261'].includes(color.parent.id)) {
                            idString = color.parent.id.toString();
                            catObj = {...color.parent};
                        }

                        if (!colorCategories[idString]) {
                            colorCategories[idString] = catObj;
                            colorCategories[idString].colors = [];
                        }
                        if (color) {
                            colorCategories[idString].colors.push(color);
                        }
                    }
                });
            } else {
                colorCategories = null;
            }

            const customSorting = {
                'glänzend': 0,
                'matt': 1,
                '530': 2,
                '4261': 3,
                '4252': 4,
            }

            // Reverse object keys
            variant.colorsByCategory = colorCategories ? Object.keys(colorCategories).sort().reverse().map(key => ({...colorCategories[key], key})) : colorCategories;

            if (variant.colorsByCategory) {
                variant.colorsByCategory = variant.colorsByCategory.sort((a, b) => {
                    return customSorting[a.key] - customSorting[b.key];
                });
            }

            // set gallery images
            let galleryImages = [];
            if (variant.images && variant.images.length) {
                variant.images.forEach((image) => {
                    if (image.assetKey === 'A_PIM_Ausschnitt' || image.assetKey === 'A_PIM_Bild') {
                        galleryImages.push(image);
                    }
                });
            } else {
                galleryImages = null;
            }
            variant.galleryImages = galleryImages;
            return variant;
        });

        if (state.product?.variants) {
            state.product.variants = state.product.variants.sort((a, b) => {
                if (a.length === b.length) {
                    return a.width - b.width;
                }

                return a.length - b.length;
            });
        }
    },
    productVariant(state, {productVariant, updateQuery}) {
        state.productVariant = productVariant;
        /*
        if (updateQuery) {
            this.$router.push({query: {variant: productVariant.externalKey }});
        }
        */
    },
    watchListAdd(state, {object}) {
        if (object.product) {
            // If is download item
            object.product = {id: object.product.id};
            state.watchList.push(object);
        } else {
            // If is variant item
            let objectExists = state.watchList.find(o => !o.download && o.id === object.id);
            if (!objectExists) {
                if(object.configuration){
                    // If is Configuration
                    state.watchList.push({configuration: true, id: object.id});
                } else {
                    state.watchList.push({configuration: false, id: object.id});
                }
            }
        }
    },
    watchListRemove(state, {object}) {
        if (object.download) {
            const getAssetId = (item) => {
                if (typeof item === undefined) {
                    return null;
                }
                return (item.assetId || item.localeAssetId).toString();
            };

            state.watchList = state.watchList.filter(o => {
                return !o.download || (o.download && getAssetId(o.download) !== getAssetId(object.download));
            });
        } else {
            state.watchList = state.watchList.filter(o => o.id !== object.id);
        }
    },
};
