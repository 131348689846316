/**
 * https://markus.oberlehner.net/blog/using-the-google-maps-api-with-vue/
 */
const CALLBACK_NAME = 'gmapsInitCallback';
let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
    resolveInitPromise = resolve;
    rejectInitPromise = reject;
});




export default ({app}, inject) => {
    const initGoogleMaps = (apiKey) => {
        // If Google Maps already is initialized
        // the `initPromise` should get resolved
        // eventually.
        if (initialized) return initPromise;

        initialized = true;
        // The callback function is called by
        // the Google Maps script if it is
        // successfully loaded.
        window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

        const init = () => {
            if (!(typeof UC_UI !== 'undefined' && UC_UI.getServicesBaseInfo().filter(data => data.id === 'S1pcEj_jZX')[0].consent.status === false)) {
                // We inject a new script tag into
                // the `<head>` of our HTML to load
                // the Google Maps script.
                const script = document.createElement('script');
                script.async = true;
                script.defer = true;
                script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${CALLBACK_NAME}&libraries=places,geometry&map_ids=2d478ae0bdbc3d19&language=${app.i18n.locale}`;
                script.onerror = rejectInitPromise;
                document.querySelector('head').appendChild(script);
            }

            window.addEventListener("ucEvent", function (e) {
                if (e.detail && e.detail.event == "consent_status") {
                    if (typeof UC_UI !== 'undefined' && UC_UI.getServicesBaseInfo().filter(data => data.id === 'S1pcEj_jZX')[0].consent.status) {
                        window.location.reload();
                    }
                }
            });
        };

        setTimeout(() => {
            if (window.UC_UI && window.UC_UI.isInitialized()) {
                init();
            }
        }, 1000);

        return initPromise;
    }

    inject('initGoogleMaps', initGoogleMaps);
}
