import ConfiguratorApi from '../services/configurator.api.js';
import ProductApi from "@/services/product.api";
import User from "@/services/user";

export default async ({app, $config}, inject) => {
    const preview = typeof app.context.query.preview !== 'undefined' && app.context.query.preview === '1' ? true : false;
    if (!app.api) {
        app.api = {};
    }

    app.api = {
        ...app.api,
        product: new ProductApi(preview, app.i18n, app.i18n.locales, app.store, $config),
        Configurator: new ConfiguratorApi(preview, app.i18n, app.i18n.locale, app.store, $config, app.$cookies),
        User: new User($config, app.i18n.locale),
    };
}
