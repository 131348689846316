//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NavigationImageTeaser',
    props: ['data', 'mobile'],
    data() {
        return {
            imageData: null,
            imagePath: this.$config.pimcoreUrl
        }
    },
    mounted() {
        this.imageData = this.data.imageBig || this.data.image || null;
    },
};
