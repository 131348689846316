//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'NavigationMobileToggle',
    props: {},
    data() {
        return {
            menuActive: false,
        };
    },
    created() {
        (async () => {
            if (process.client) {
                const { bodyScroll } = await import('../../../assets/js/preventBodyScroll.js');
                this.bs = bodyScroll;
            }
        })();
    },
    methods: {
        toggleMobileMenu() {
            if (this.menuActive){
                this.closeMobileMenu();
            } else {
                this.openMobileMenu();
            }
        },
        openMobileMenu() {
            this.menuActive = true;
            this.$nuxt.$emit('mobile-nav.open');
            this.$nuxt.$emit('navigation.state', {navOpen: true});
            this.bs(false);
        },
        closeMobileMenu() {
            this.menuActive = false;
            this.$nuxt.$emit('mobile-nav.close');
            this.$nuxt.$emit('navigation.state', {navOpen: false});
            this.$nuxt.$emit('mobile-nav.level', {level: 0});
            this.bs(true);
        }
    },
    mounted() {
        this.$nuxt.$on('mobile-toggle.open', (data) => {
            this.menuActive = true;
            this.$nuxt.$emit('mobile-nav.open', data);
            this.$nuxt.$emit('navigation.state', {navOpen: true});
        });
    }
};
