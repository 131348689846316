//
//
//
//
//
//
//

export default {
    name: 'Picture',
    props: {
        image: {
            type: Object,
            default: false,
        },
    },
    computed: {
        srcsetAttr () {
            if (!this.image?.srcset?.length) {
                return null;
            }

            return this.image.srcset.map(srcset => {
                const reso = srcset.resolutions[0];
                return `${ this.resolvePimcoreUrl(reso.url) } ${ srcset.descriptor }`;
            }).join(', ');
        },
        baseSrc() {
            const url = this.image?.resolutions[0]?.url || null;
            if (url === null) {
                return null;
            }
            return this.resolvePimcoreUrl(url);
        },
    },
    methods: {
        resolvePimcoreUrl (path) {
            return `${ this.$config.pimcoreUrl }/${ path.charAt(0) === '/' ? path.substr(1) : path }`;
        },
    }
};
