//
//
//
//
//
//
//

import NavigationMeta from './NavigationMeta.vue';
import NavigationMain from './NavigationMain.vue';

export default {
    name: 'Navigation',
    components: {NavigationMain, NavigationMeta},
    mounted() {
        // is focus outside this element? then close open submenus
        document.body.addEventListener('keyup', ({keyCode}) => {
            if (keyCode === 9) {
                window.requestAnimationFrame(() => {
                    if (!this.$el.contains(document.activeElement)) {
                        this.$nuxt.$emit('submenu.toggle', {toggle: 0});
                    }
                });
            }
        });
    }
};
