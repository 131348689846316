import { render, staticRenderFns } from "./NavigationSubmenu.vue?vue&type=template&id=6bd22178&"
import script from "./NavigationSubmenu.vue?vue&type=script&lang=js&"
export * from "./NavigationSubmenu.vue?vue&type=script&lang=js&"
import style0 from "./NavigationSubmenu.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubmenuLink: require('/usr/src/app/components/Navigation/SubmenuLink.vue').default,NavigationSubmenuFlyout: require('/usr/src/app/components/Navigation/NavigationSubmenuFlyout.vue').default})
