//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'NavigationMobileLevel2',
    props: ['subpages', 'open', 'headline', 'flyout', 'page'],
    data() {
        return {
            expanded: false,
            subFlyoutId: 0,
            bs: null,
        };
    },
    watch: {
        expanded() {
            if (this.expanded) {
                this.$nuxt.$emit('mobile-nav.level', {level: 2});
            } else {
                this.$nuxt.$emit('mobile-nav.level', {level: 1});
            }
        }
    },
    mounted() {
        if (this.subpages) {
            this.$nuxt.$on('mobile-nav.level2.open', ({element}) => {
                this.expanded = (element === this.$el) || false;
            });
            this.$nuxt.$on('mobile-nav.close', () => {
                this.expanded = false;
            });
        }
    },
    methods: {
        subLinksVisible(subPages) {
            const subPageHiddenValues = subPages.map(subPages => !!subPages.hidden);
            return (subPageHiddenValues.includes(null) || subPageHiddenValues.includes(false));
        }
    },
};
