import Vue from 'vue';
import 'assets/css/vendor/dtoaster.css'
import DToaster from './dtoaster'

Vue.use(DToaster, {
    presets: [
        {
            "name" : "success",
            "duration" : "4000",
            "background" : "#003d4c",
            "icon" : "link link--icon link--copy",
            "height" : "75px",
            "color" : "#FFFFFF",
            "show_progress_timeout" : "true",
            "progress_background" : "#5EDFC5",
            "progress_thumb_bg" : "#0B8677"
        },
        {
            "name" : "error",
            "duration" : "4000",
            "background" : "#FF6A60",
            "icon" : "link link--icon link--copy",
            "height" : "75px",
            "color" : "#FFFFFF",
            "show_progress_timeout" : "true",
            "progress_background" : "#FFB2AD",
            "progress_thumb_bg" : "#EE3529"
        },
    ],
    position: 'top-right'
})
