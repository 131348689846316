import axios from 'axios';
import { assembleValueKey } from '../components/Configurator/services/utils';
import { log } from '../components/Configurator/services/utils';

process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = 0;

export class ValidationError extends Error {
    constructor(message, data) {
        super(message);
        this.name = 'ValidationError';
        this.data = data;
    }
}

export default class ConfiguratorApi {
    constructor(preview, i18n, locales, store, config, cookies) {
        this.preview = preview;
        this.i18n = i18n;
        this.locale = i18n.locale;
        this.locales = locales;
        this.store = store;
        this.config = config;
        this.cookies = cookies;
    }

    renderPDF(isDocX, structuredList, screenshots = null, addedPrice = null, variantPrice = null, currentProductRangeCountry = null) {
        return new Promise((resolve) => {
            log(`Request PDF`);
            structuredList.fullPrice = addedPrice;
            structuredList.additionalAccessories = structuredList.accessories.map((a) => {
                if (!structuredList.product.sku.includes(a.externalKey)) {
                    return a.externalKey;
                }
            });
            structuredList.taxNote = currentProductRangeCountry ? 'product.info.price.note_' + currentProductRangeCountry.toLowerCase() : null;
            const data = JSON.stringify(structuredList);
            // Create a form dynamically
            const form = document.createElement('form');
            form.setAttribute('method', 'post');

            if (isDocX) {
                form.setAttribute('action', `${this.config.pimcoreUrl}/${this.locale}/download_docx`);
            } else {
                form.setAttribute('action', `${this.config.pimcoreUrl}/${this.locale}/pdf`);
            }

            // Create an input element for json payload
            const payload = document.createElement('input');
            payload.setAttribute('type', 'hidden');
            payload.setAttribute('name', 'payload');
            payload.setAttribute('value', data);

            if (Array.isArray(screenshots)) {
                screenshots.forEach((screenshot, s) => {
                    // Create an input element for attachment
                    const attachment = document.createElement('input');
                    attachment.setAttribute('type', 'hidden');
                    attachment.setAttribute('name', `attachment-${s}`);
                    attachment.setAttribute('value', screenshot);
                    form.appendChild(attachment);
                });
            }

            form.appendChild(payload);
            form.setAttribute('hidden', 'hidden');

            document.body.appendChild(form);
            form.submit();
            form.remove();

            resolve();
        });
    }

    getConfigurationByCode(code) {
        return new Promise((resolve, reject) => {
            const parts = [];

            if (this.preview) {
                parts.push(`v${Date.now()}`);
            }

            // TODO: change hardcoded locale
            axios
                .post(`${this.config.pimcoreUrl}/${this.locale}/configuration/${code}?${parts.join('&')}`)
                .then(async (res) => {
                    if (!res.data) {
                        return reject();
                    }

                    resolve(res.data?.data);
                })
                .catch(reject);
        });
    }

    saveConfiguration(configuration, code, colorClass = null, productRange) {
        return new Promise((resolve, reject) => {
            const parts = [];

            if (this.preview) {
                parts.push(`v${Date.now()}`);
            }

            const codePart = code ? `/${code}` : '';

            configuration.productRange = productRange ?? null;
            configuration.colorClass = colorClass;

            // TODO: change hardcoded locale
            axios
                .post(`${this.config.pimcoreUrl}/${this.locale}/configuration${codePart}?${parts.join('&')}`, configuration)
                .then(async (res) => {
                    if (!res.data) {
                        return reject();
                    }

                    resolve(res.data?.code);
                })
                .catch(reject);
        });
    }

    updateConfiguration(code, structuredList, screenshots) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('pdfData', JSON.stringify(structuredList));
            for (let i = 0; i < screenshots.length; i++) {
                formData.append('pdfImages[]', screenshots[i]);
            }

            axios
                .post(`${this.config.pimcoreUrl}/update_configuration/${code}`, formData)
                .then(resolve)
                .catch(reject);
        });
    }

    getStepsConfig(variantId, params = null) {
        return new Promise((resolve, reject) => {
            const parts = [];

            if (this.preview) {
                parts.push(`v${Date.now()}`);
            }

            if (params !== null) {
                // Encode nested query params
                const p = require('qs').stringify({ params: params }, { encodeValuesOnly: true });
                parts.push(p);
            }

            log(`FETCH ALL step data for ${variantId} with ${parts}`);

            axios
                .get(`${this.config.pimcoreUrl}/${this.locale}/features/${variantId}?${parts.join('&')}`)
                .then(async (res) => {
                    if (!res.data) {
                        return resolve([]);
                    }

                    groupProducts(res.data);

                    resolve(res.data);
                })
                .catch((e) => {
                    // 409, conflicting configuration
                    if (e.response?.status === 409) {
                        const data = e.response.data;

                        groupProducts(data);

                        reject(new ValidationError('Conflicting configuration', data));
                        return;
                    }

                    reject(e);
                });
        });
    }
}

/**
 * Group category's products by attribute
 * NOTE: Mutates data
 * @param data
 */
function groupProducts(data) {
    const steps = data.steps;
    Object.keys(steps).forEach((stepKey) => {
        const step = steps[stepKey];

        step.categories.forEach((category) => {
            const groups = {};

            category.products.forEach((product) => {
                // TODO: Map to group by proper category name/title/attribute
                const name = product.detailCategory || 'unknown';
                if (!groups[name]) {
                    groups[name] = {
                        name,
                        products: [],
                    };
                }

                groups[name].products.push(product);
            });

            category.groups = Object.values(groups);
        });
    });
}
