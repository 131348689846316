import AOS from 'aos'
import 'aos/dist/aos.css'

export default ({ app }) => {
    app.AOS = new AOS.init({
        duration: 1000,
        delay: 350,
        offset: 150,
    });

    const resizeObserver = new ResizeObserver(() => {
        AOS.refresh()
    })
    resizeObserver.observe(document.body)

};
