export default {
    async nuxtServerInit({commit}, {app}) {
        // Load and set menu data
        const menu = await app.api.website.getMenu(app.i18n.locale);
        commit('menu', menu);

        // Load and set site properties
        const siteProperties = await app.api.website.getSiteProperties(app.i18n.locale);
        commit('siteProperties', siteProperties);
    },

    /**
     * Sets the selected product variant
     * @param commit
     * @param productVariant
     * @param updateQuery
     */
    setStoredProductVariant({commit}, productVariant, updateQuery) {
        commit('productVariant', {productVariant: productVariant, updateQuery: updateQuery});
    },

    addToWatchList({commit}, object) {
        commit('watchListAdd', object);
    },

    removeFromWatchList({commit}, object) {
        commit('watchListRemove', object);
    },

    browserLanguage({commit}, object) {
        commit('browserLanguage', object);
    },
};
