//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['error'],
    layout: 'default',
    computed: {
      href() {
          if (this.$i18n.locale != 'de') {
              return '/' + this.$i18n.locale;
          }
          return '/'
      }
    },
}
