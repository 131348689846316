export const PRODUCT_CATEGORY_TYPE_BATHTUB = 'bathtub';
export const PRODUCT_CATEGORY_TYPE_FURNITURE = 'furniture';
export const PRODUCT_CATEGORY_TYPE_EQUIPMENT = 'equipment';
export const PRODUCT_CATEGORY_TYPE_SHOWERSURFACE = 'Duschfläche';
export const PRODUCT_CATEGORY_TYPE_SHOWERTILE = 'Duschfliese';

export const PRODUCT_CATEGORIES_IDS = {};
PRODUCT_CATEGORIES_IDS[PRODUCT_CATEGORY_TYPE_BATHTUB] = [2213, 2214, 2216];
PRODUCT_CATEGORIES_IDS[PRODUCT_CATEGORY_TYPE_FURNITURE] = [2217];
PRODUCT_CATEGORIES_IDS[PRODUCT_CATEGORY_TYPE_EQUIPMENT] = [2215];

export const WATCHABLE = true;
export const SHOWPRICES = true;

export const ENRICHED_PRESS_RELEASE = 'PressRelease';
export const ENRICHED_REFERENCE = 'Reference';
export const ENRICHED_NEWS = 'News';
