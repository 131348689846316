//
//
//
//
//
//
//
//
//

export default {
    name: "FormInput",
    props: ['clearable', 'value', 'isSearch', 'extraSearchButton'],
    data() {
        return {
            data: '',
        };
    },
    watch: {
        value(val) {
            this.data = val;
        }
    },
    mounted() {
        this.data = this.value ? this.value.toString() : '';
        this.$refs.input.focus();
    }
}
