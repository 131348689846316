//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';

export default {
    name: 'NavigationMobileMeta',
    data() {
        return {
            expanded: false,
            opened: 1,
            meta: null,
            showLink: null,
            userData: null,
        };
    },
    computed: {
        ...mapGetters(['getStoredSiteProperties']),
    },
    created() {
        try {
            this.meta = this.getStoredSiteProperties.meta.mobile;
        } catch (e) {
            console.error(e);
        }
    },
    async mounted() {
        const accessToken = window.localStorage.getItem('access_token');
        if (!accessToken) {
            this.showLink = 'account';
        } else {
            this.userData = await this.$nuxt.context.app.api.User.userData();
            this.showLink = this.userData.isLoggedIn ? 'login' : 'account';
        }
        this.meta = this.meta.filter((entry) => entry?.link?.title !== this.showLink);
    },
};
