//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'SubmenuLink',
    props: {
        id: {
            type: String,
            required: true
        },
        children: {
            type: [Boolean, Number],
            default: false
        },
        open: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        isExternalLink: {
            type: Boolean,
            default: false
        },
        href: {
            type: String,
            required: true
        }
    },
    computed: {
        hasChildren() {
            return Boolean(this.children);
        },
        target() {
            return this.isExternalLink ? '_blank' : null;
        }
    },
    methods: {
        handleSubFlyout(event) {
            if (this.isExternalLink) {
                return true;
            }
            event.preventDefault();
            if (this.hasChildren) {
                this.$nuxt.$emit('toggle', this.id);
                this.$emit('toggle', this.id);
            } else {
                window.location = this.href;
            }
        }
    }
};
