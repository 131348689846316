import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _e542f99c,
    name: "index___en"
  }, {
    path: "/es",
    component: _e542f99c,
    name: "index___es"
  }, {
    path: "/fr",
    component: _e542f99c,
    name: "index___fr"
  }, {
    path: "/it",
    component: _e542f99c,
    name: "index___it"
  }, {
    path: "/nl",
    component: _e542f99c,
    name: "index___nl"
  }, {
    path: "/ru",
    component: _e542f99c,
    name: "index___ru"
  }, {
    path: "/zh",
    component: _e542f99c,
    name: "index___zh"
  }, {
    path: "/en/:slug(.*)",
    component: _e542f99c,
    name: "catchAll___en"
  }, {
    path: "/es/:slug(.*)",
    component: _e542f99c,
    name: "catchAll___es"
  }, {
    path: "/fr/:slug(.*)",
    component: _e542f99c,
    name: "catchAll___fr"
  }, {
    path: "/it/:slug(.*)",
    component: _e542f99c,
    name: "catchAll___it"
  }, {
    path: "/nl/:slug(.*)",
    component: _e542f99c,
    name: "catchAll___nl"
  }, {
    path: "/ru/:slug(.*)",
    component: _e542f99c,
    name: "catchAll___ru"
  }, {
    path: "/zh/:slug(.*)",
    component: _e542f99c,
    name: "catchAll___zh"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___de"
  }, {
    path: "/:slug(.*)",
    component: _e542f99c,
    name: "catchAll___de"
  }],

  parseQuery: function(query) {
            return require('qs').parse(query);
        },
  stringifyQuery: function(query) {
            const result = require('qs').stringify(query, { encodeValuesOnly: true });
            return result ? ('?' + result) : '';
        },
  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decodeURIComponent(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
