//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NavigationLinklist',
    props: ['data', 'mobile'],
};
