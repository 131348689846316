import axios from "axios";
import Cache from '../services/cache';

export default async ({app, $config}, inject) => {
    const setAppTranslations = (items) => {
        let values = {};
        for (const lang in items) {
            values = items[lang];
            app.i18n.setLocaleMessage(lang, values);
        }
    };

    try {
        const res = await axios.get($config.pimcoreUrl + '/translations');
        setAppTranslations(res.data);
    } catch (e) {
        console.error(e);
    }
}
