/**
 * https://stackoverflow.com/a/13070198
 * @param rgb
 * @return {string|null}
 */
export function rgb2hex (rgb) {
    if (!rgb) {
        return null;
    }

    const hex = rgb
        .split("(")[1]
        .split(")")[0]
        .split(",").map(function (x) {
            const y = parseInt(x).toString(16);
            return y.length === 1 ? '0' + y : y;
        })
        .join('')

    return '#' + hex;
}

/**
 * Compare two values for equality (dirty check)
 *
 * @param {*} a
 * @param {*} b
 * @return {boolean}
 */
export function isDeeplyEqual (a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}

/**
 * Copy text to clipboard
 *
 * @param text
 * @return {boolean}
 */
export function copyToClipboard (text) {
    // clipboard api supported?
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
    } else {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return true;
}

/**
 * Returns a new object where empty properties are omitted
 *
 * @param {Object} obj
 * @return {Object|null}
 */
export function omitEmptyProps (obj) {
    if (!obj) {
        return null;
    }

    return Object.keys(obj).reduce((acc, key) => {
        let value = obj[key];
        const isArray = Array.isArray(value);
        const isObject = typeof value === 'object' && value !== null;

        if (isObject) {
            value = omitEmptyProps(value);
        }

        if (
            value === null ||
            value === undefined ||
            (isArray && value.length === 0) ||
            (isObject && Object.keys(value).length === 0)
        ) {
            return acc;
        }

        return {
            ...acc,
            [key]: value,
        }
    }, {});
}

/**
 * Assemble parent:child identifier combination
 *
 * @param parentKey
 * @param childKey
 * @return {string}
 */
export function assembleValueKey (parentKey, childKey) {
    return `${ parentKey }--${ childKey }`;
}

/**
 * Split value key in it's parts
 *
 * @param {String} valueKey
 * @return {Array}
 */
export function dismantleValueKey (valueKey) {
    return valueKey.split('--');
}

/**
 * Select image object form variant
 *
 * @param variant
 * @param {string} targetAssetKey
 * @return {*|null}
 */
export function getImageFromVariant (variant, targetAssetKey = 'A_PIM_Bild') {
    if (!variant) {
        return null;
    }

    const criteria = variant?.images?.length > 0
        ? img => img.assetKey === targetAssetKey
        : () => true;

    return variant?.images?.find(criteria)?.gallery || null;
}

/**
 * Get then next best image for variant
 * @param variant
 * @return {*|null}
 */
export function getFallbackImageFromVariant(variant) {
    const image = getImageFromVariant(variant);
    return image?.resolutions[0]?.url || null;
}

/**
 * Generate code url
 * NOTE: Only works on client
 *
 * @param code
 * @return {string|null}
 */
export function getExpandedCodeUrlOnClient(code) {
    if (!code || !process.client) return null;
    const {protocol, host, pathname} = window.location;

    let path = '';
    const pathPart = pathname.split('/').slice(0, -1).join('/');

    if (pathPart !== '/') {
        path = pathPart
    }

    return `${protocol}//${host}${path}/code/${encodeURIComponent(code)}`;
}

export function log(...messages) {
    if (process.env.NODE_ENV !== 'production') {
        console.log(...messages);
    }
}
