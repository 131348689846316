// OXOMI JavaScript Bibliothek einbinden und laden
setTimeout(function() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = (window.location.protocol == 'https:' ? 'https:' : 'http:')
        + "//" + (typeof oxomi_server == 'undefined' ? 'oxomi.com' : oxomi_server)
        + "/assets/frontend/oxomi.js";
    document.getElementsByTagName("head")[0].appendChild(script);
}, 0);


window.oxomi_ready = function() {
    //console.log('init');

    // Initialisierung
    oxomi.init({
        "portal": "2024579",
        "filterLang": document.querySelector('html').getAttribute('lang'),
        "lang": document.querySelector('html').getAttribute('lang')
    });
};
