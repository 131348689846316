//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NavigationTeaser',
    props: ['data', 'mobile'],
    data() {
        return {
            imagePath: this.$config.pimcoreUrl,
        }
    },
    computed: {
        image() {
            return this.$mapImageData(this.data?.image);
        },
    },
};
