export default ({ store, app: { router, context, i18n } }, inject) => {
    if (i18n.locale === 'de') {
        // Add script tag to head
        let script = document.createElement("script")
        script.async = true
        script.id = "gtag"
        script.src = "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/23261df2255147b7a71eded889a6b0dd77faccb475824611a2a7209c0bdb32c0.js"
        document.head.appendChild(script)
    } else if (i18n.locale === 'en' || i18n.locale === 'it' || i18n.locale === 'es') {
        // Add script tag to head
        let script = document.createElement("script")
        script.async = true
        script.id = "gtag"
        script.src = "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/a3001d174a7c4e40a5c3bf54cb43f9b893b8a4b3a8fb45dfaad29e38f45d0bbb.js"
        document.head.appendChild(script)
    } else if (i18n.locale === 'fr') {
        // Add script tag to head
        let script = document.createElement("script")
        script.async = true
        script.id = "gtag"
        script.src = "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/018fcd5d12a6429bb556f8aa37c2c7fba343715b07cf41bf9e33dbf53c3c5b54.js"
        document.head.appendChild(script)
    } else if (i18n.locale === 'nl') {
        // Add script tag to head
        let script = document.createElement("script")
        script.async = true
        script.id = "gtag"
        script.src = "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/6a00ced3c6324cebb4e90c143c3085142e0ebb36d276448d8a87bbca6c17ac14.js"
        document.head.appendChild(script)
    }
}
