export default () => ({
    pageSlugs: null,
    menu: null,
    page: null,
    rootLine: [],
    pageTranslations: {},
    watchList: [],
    siteProperties: null,
    product: null,
    productVariant: null,
    browserLanguage: null,
    showProductRangeOverlay: false,
});
